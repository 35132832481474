
import { GameManage_GMGetServerKeyList } from "@/apis/game_manage";
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({

    setup() {
        const { t } = useI18n()
        const dataMap = reactive(new ViewData())
        const getServerKeyInfo = async function () {
            dataMap.listLoading = true;
            let cbmsg = await GameManage_GMGetServerKeyList();
            if (cbmsg?.Error !== 0) {
                ElMessage.error(`getServerKeyInfo: ${cbmsg?.Message}`);
                return;
            }
            const items = JSON.parse(cbmsg?.Message!) as IServerKey[];
            items.sort((a, b) => { return a.Label.localeCompare(b.Label) })
            items.forEach(v => {
                dataMap.list.push(v);
            })
            dataMap.listLoading = false;
        }


        onMounted(() => {
            getServerKeyInfo()
        })

        return {
            t, ...toRefs(dataMap),
            getServerKeyInfo,

        }
    }
});

class ViewData {
    public tableKey = 0;
    public list: IServerKey[] = [];
    public listLoading = true;

}
