<template>
    <div class="app-container">
        <el-table :key="tableKey" v-loading="listLoading" :data="list" border fit highlight-current-row
            style="width: 100%;">
            <el-table-column :label="`游戏名字`" prop="id" align="center" width="80">
                <template #default="{ row }">
                    <span>{{ row.Label }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('server_manage.createtime')" width="180px" align="center">
                <template #default="{ row }">
                    <span>{{ row.CreateTime }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="`Key参数`" width="150px" align="center">
                <template #default="{ row }">
                    <span>{{ row.Name }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="'ServerKey'" width="280px" align="center">
                <template #default="{ row }">
                    <span>{{ row.ServerKey }}</span>
                </template>
            </el-table-column>
        </el-table>


    </div>
</template>
  
<script lang="ts">
import { GameManage_GMGetServerKeyList } from "@/apis/game_manage";
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({

    setup() {
        const { t } = useI18n()
        const dataMap = reactive(new ViewData())
        const getServerKeyInfo = async function () {
            dataMap.listLoading = true;
            let cbmsg = await GameManage_GMGetServerKeyList();
            if (cbmsg?.Error !== 0) {
                ElMessage.error(`getServerKeyInfo: ${cbmsg?.Message}`);
                return;
            }
            const items = JSON.parse(cbmsg?.Message!) as IServerKey[];
            items.sort((a, b) => { return a.Label.localeCompare(b.Label) })
            items.forEach(v => {
                dataMap.list.push(v);
            })
            dataMap.listLoading = false;
        }


        onMounted(() => {
            getServerKeyInfo()
        })

        return {
            t, ...toRefs(dataMap),
            getServerKeyInfo,

        }
    }
});

class ViewData {
    public tableKey = 0;
    public list: IServerKey[] = [];
    public listLoading = true;

}
</script>
  